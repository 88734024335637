import React, { createContext, useState } from "react";

// Create Cart Context
export const CartContext = createContext();

// CartProvider component to wrap around the app
export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    // Function to add items to the cart
    const addToCart = (item) => {
        setCartItems((prevItems) => {
            const existingItem = prevItems.find((i) => i.id === item.id && i.size === item.size && i.color === item.color);
            if (existingItem) {
                // Update quantity if item already exists in the cart
                return prevItems.map((i) =>
                    i.id === item.id && i.size === item.size && i.color === item.color ? { ...i, quantity: i.quantity + item.quantity } : i
                );
            }
            return [...prevItems, item];
        });
    };

    // Function to remove items from the cart
    const removeFromCart = (id) => {
        setCartItems(cartItems.filter((item) => item.id !== id));
    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
            {children}
        </CartContext.Provider>
    );
};
