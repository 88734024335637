import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import H_MList from "./H&MList";

const categories = [
    {
        name: "Campaigns",
        component: H_MList, // Replace with a component when you have one for Campaigns
    },
    {
        name: "Womenswear",
        ariaLabel: "Browse Womenswear category",
        component: H_MList,
    },
    {
        name: "Menswear",
        ariaLabel: "Browse Menswear category",
        component: H_MList, // Replace with a component when you have one for Menswear
    },
    {
        name: "Kids",
        ariaLabel: "Browse Kids category",
        component: H_MList, // Replace with a component when you have one for Kids
    }
];

const H_M = () => {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleBackClick = () => {
        navigate('/fashion');
    };

    const handleCBackClick = () => {
        setSelectedCategory(null);
    };

    const renderCategory = () => {
        const category = categories.find(cat => cat.name === selectedCategory);
        return category && category.component ? React.createElement(category.component, { title: category.name }) : null;
    };

    return (
        <section className="fashion" role="main">
            <h1>H&M accessible store</h1>
            {!selectedCategory ? (
                <>
                    <span>H&M for the visually impaired.</span>
                    <div className="mt-4">
                        <h2 className="mb-4">Choose a category</h2>
                        <div className="category">
                            {categories.map((category, index) => (
                                <button
                                    key={index}
                                    onClick={() => setSelectedCategory(category.name)}
                                    className="btn btn-category"
                                    aria-label={category.name}
                                >
                                    {category.name}
                                </button>
                            ))}
                        </div>
                    </div>
                    <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">Back</button>
                </>
            ) : (
                <div className="category-content">
                    {renderCategory()}
                    <button onClick={handleCBackClick} className="btn btn-back" aria-label="Go back to categories">
                        Back
                    </button>
                </div>
            )}
        </section>
    );
};

export default H_M;
