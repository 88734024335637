import React from "react";
import './HowItWorks.scss';
import { useNavigate } from 'react-router-dom';

const HowItWorks = () => {
    const navigate = useNavigate(); // Initialize the hook
    const handleBackClick = () => { 
        // Navigate to home page
       navigate('/');
    };
    return (
        <div className="how-it-works-page" role="main">
            <h1>How It Works</h1>
            <p>Welcome to the How It Works page! Here's a step-by-step explanation:</p>
            <ol aria-label="Steps to place an order">
                <li>Step 1: Choose the items you need.</li>
                <li>Step 2: Add them to your cart.</li>
                <li>Step 3: Proceed to checkout and confirm your order.</li>
                <li>Step 4: Receive your items at your preferred location.</li>
            </ol>
            <p>It's as simple as that! Feel free to explore more options.</p>

            <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">Back</button>
        </div>
    );

};

export default HowItWorks;