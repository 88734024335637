import React, { useContext } from 'react';
import ShoppingCart from '../assets/Icons/cart.svg';
import { CartContext } from './context/CartContext';

const Cart = () => {
  const { cartItems } = useContext(CartContext);

  return (
    <div id='cart' className="d-flex align-items-center justify-content-center mt-3 mb-3 cart">
      <h2 className="fw-bold me-3">{cartItems.length} items in cart</h2>
      <img src={ShoppingCart} alt="Shopping cart icon" className="w-10" />
    </div>
  );
};

export default Cart;
