import React from "react";
import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard';
import Cart from './Cart';
import FilterAccordion from './FilterAccordion';
import Tshirt from '../assets/Images/t-shirt.png';
import './ProductList.scss';

const ProductList = () => {
    const navigate = useNavigate(); // Initialize the hook
    const handleBackClick = () => {
        navigate('/H&M'); // Navigate to home page
    }

    const category = "Tops"; // Define the category here

    const products = [
        {
            id:1,
            name: "Long Sleeved t-shirt",
            price: 34.99,
            imageUrl: Tshirt,
        },
        {
            id:2,
            name: "Short Sleeved t-shirt",
            price: 29.99,
            imageUrl: Tshirt,
        },
        {
            id:3,
            name: "Tank Top",
            price: 24.99,
            imageUrl: Tshirt,
        },
    ];

    return (
        <div className="product-list" role="main">
            <div>
                <h6>Category: Women &gt; {category}</h6>
                <Cart itemCount={3} />
            </div>
            <h1>Women's Tops</h1>

            <FilterAccordion filterCount={2} />
            
            <div className="filter-list">
                {products.map((product, index) => (
                    <ProductCard 
                        key={index}
                        id={product.id}
                        name={product.name}
                        price={product.price}
                        imageUrl={product.imageUrl}
                    />
                ))}
            </div>
            <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">Back</button>
        </div>
    );
};

export default ProductList;
