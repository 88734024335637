import React, { useContext, useState } from "react";
import { CartContext } from "./context/CartContext"; // Import CartContext
import Tshirt from '../assets/Images/t-shirt.png';
import { Link, useNavigate} from 'react-router-dom';
import './ProductDetails.scss';

const ProductDetails = () => {
    const navigate = useNavigate();
    const { addToCart } = useContext(CartContext); // Get addToCart function from context
    // Local state for selected options
    const [selectedSize, setSelectedSize] = useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [announcement, setAnnouncement] = useState("");

    // Define the product details dynamically
    const product = {
        id: 1,
        name: "Long Sleeved t-shirt",
        price: 34.99,
        imageUrl: Tshirt,
        originalPrice: 51.99,
        description: "This is a detailed product description. It provides an overview of the features, benefits, and specifications of the product.",
        rating: "4.5 of 5",
        sizeChart: "This is the size chart. It provides measurements and size information for different products.",
        moreImages: "This section contains more images of the product.",
        availableSizes: ["Small", "Medium", "Large"], // Dynamic sizes
        availableColors: ["Blue", "Black", "Gray", "Red"], // Dynamic colors
    };

    const handleQuantityChange = (newQuantity) => {
        const quantityValue = Math.max(1, Number(newQuantity)); // Ensure minimum value is 1
        setQuantity(quantityValue);
        setAnnouncement(`Selected quantity is ${quantityValue} piece${quantityValue > 1 ? 's' : ''}`);
    };
    

    const [isOpen, setIsOpen] = useState(false); // State to toggle the dropdown
    const [isRatingOpen, setIsRatingOpen] = useState(false);
    const [isSizeChartOpen, setIsSizeChartOpen] = useState(false);
    const [isMoreImagesOpen, setIsMoreImagesOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const toggleRatingDropdown = () => setIsRatingOpen(!isRatingOpen);
    const toggleSizeChartDropdown = () => setIsSizeChartOpen(!isSizeChartOpen);
    const toggleMoreImagesDropdown = () => setIsMoreImagesOpen(!isMoreImagesOpen);

    const handleKeyDown = (e, toggleFunction) => {
        // Allow toggling the dropdown using Enter or Space keys
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault(); // Prevent scrolling when Space is pressed
            toggleFunction();
        }
    };

    const handleSizeChange = (e) => {
        const size = e.target.value;
        setSelectedSize(size);
        setAnnouncement(`Selected size is ${size}`);
    };

    const handleColorChange = (e) => {
        const color = e.target.value;
        setSelectedColor(color);
        setAnnouncement(`Selected color is ${color}`);
    };


    const handleAddToCart = () => {
        const newItem = {
            id: product.id,
            name: product.name,
            price: product.price,
            quantity: parseInt(quantity),
            size: selectedSize,
            color: selectedColor,
        };
        addToCart(newItem); // Add item to the global cart
        navigate('/cartlist'); // Redirect to cart list after adding
    };

    const handleBackClick = () => navigate('/productlist');

    return (
        <div className="product-details" role="main">
            <div className="bg-gray-200 p-2 text-sm">
                <span>Category: Women &gt; shirts</span>
            </div>

            <div className="product-info">
                <h1 className=" mb-4">{product.name}</h1>

                <div className="mb-4">
                    <img src={product.imageUrl} alt={product.name} className="filter-img" />
                </div>

                <div className="details">
                    <h2 className="fs-1 fw-bold">Price: ${product.price.toFixed(2)}</h2>
                    <h3 className="fs-5 fw-medium text-body">Original price: ${product.originalPrice.toFixed(2)}</h3>
                </div>

                <button 
                    className="heading mt-3"
                    onClick={toggleDropdown} 
                    onKeyDown={(e) => handleKeyDown(e, toggleDropdown)} 
                    tabIndex={0}  
                    aria-expanded={isOpen}  
                    style={{ cursor: 'pointer' }}
                >
                    <span>Product description</span>
                    <i className={isOpen ? "las la-angle-up" : "las la-angle-down"}></i>
                </button>

                {isOpen && (
                    <div className="description-content mt-2">
                        <p>{product.description}</p>
                    </div>
                )}

                <div className="mb-4">
                    <fieldset className="size-color-selection">
                        <legend className="visually-hidden">Select preferred options for the product</legend>
                        
                        <select 
                            value={selectedSize} 
                            onChange={(e) => setSelectedSize(e.target.value)} 
                            className="btn-default" 
                            aria-label="Select size"
                        >
                            <option value="">Choose an size</option>
                            {product.availableSizes.map((size) => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </select>
                        
                        <select 
                            value={selectedColor} 
                            onChange={(e) => setSelectedColor(e.target.value)} 
                            className="btn-default mt-4" 
                            aria-label="Select color"
                        >
                            <option value="">Choose a color</option>
                            {product.availableColors.map((color) => (
                                <option key={color} value={color}>{color}</option>
                            ))}
                        </select>
                    </fieldset>

                    <div aria-live="polite" aria-atomic="true" style={{ position: 'absolute', left: '-9999px' }}>
                        {announcement}
                    </div>
                    <div className="mt-4 d-flex justify-content-end align-items-center">
                        <h2 className="fw-bold fs-3">Quantity</h2>
                        <div className="dropdown ms-2">
                            <button 
                            className="btn btn-default dropdown-toggle" 
                            type="button" 
                            id="quantityDropdown" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false"
                            >
                            {quantity} pc
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="quantityDropdown">
                            {[...Array(10).keys()].map((num) => (
                                <li key={num + 1} aria-label={` ${num + 1} pc`} className="dropdown-item" 
                                onClick={() => handleQuantityChange(num + 1)}>
                                
                                    <span>{num + 1} pc</span>
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <button 
                    className="btn btn-primary" 
                    onClick={handleAddToCart} 
                    disabled={!selectedSize || !selectedColor} // Disable button if options are not selected
                    
                >
                    Add to cart
                </button>

                {/* Rating Dropdown */}
                <button 
                    className="heading" 
                    onClick={toggleRatingDropdown}
                    onKeyDown={(e) => handleKeyDown(e, toggleRatingDropdown)} 
                    tabIndex={0} 
                    aria-expanded={isRatingOpen} 
                    style={{ cursor: 'pointer' }}
                >
                    <span>Rating {product.rating}</span>
                    <i className={isRatingOpen ? "las la-angle-up" : "las la-angle-down"}></i>
                </button>
                {isRatingOpen && (
                    <div className="description-content mt-2">
                        <p>
                            This is the detailed product rating. It includes reviews, scores, and customer feedback.
                        </p>
                    </div>
                )}

                {/* Size Chart Dropdown */}
                <button 
                    className="heading" 
                    onClick={toggleSizeChartDropdown} 
                    onKeyDown={(e) => handleKeyDown(e, toggleSizeChartDropdown)} 
                    tabIndex={0} 
                    aria-expanded={isSizeChartOpen} 
                    style={{ cursor: 'pointer' }}
                >
                    <span>Size chart</span>
                    <i className={isSizeChartOpen ? "las la-angle-up" : "las la-angle-down"}></i>
                </button>
                {isSizeChartOpen && (
                    <div className="description-content mt-2">
                        <p>{product.sizeChart}</p>
                    </div>
                )}

                {/* More Images Dropdown */}
                <button 
                    className="heading" 
                    onClick={toggleMoreImagesDropdown} 
                    onKeyDown={(e) => handleKeyDown(e, toggleMoreImagesDropdown)} 
                    tabIndex={0} 
                    aria-expanded={isMoreImagesOpen} 
                    style={{ cursor: 'pointer' }}
                >
                    <span>More images</span>
                    <i className={isMoreImagesOpen ? "las la-angle-up" : "las la-angle-down"}></i>
                </button>
                {isMoreImagesOpen && (
                    <div className="description-content mt-2">
                        <p>{product.moreImages}</p>
                    </div>
                )}

                <div className="fs-5 fw-medium text-center">
                    <Link to="/productlist">
                        View similar products &gt;&gt;
                    </Link>
                </div>
            </div>

            <button className="btn btn-back" aria-label="Go back" onClick={handleBackClick}>Back</button>
        </div>
    );
};

export default ProductDetails;
