import React, { useState, useEffect } from 'react';
import './Header.scss';
import {Link}  from 'react-router-dom';

const Header = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [announcement, setAnnouncement] = useState('');

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
    // Update the announcement message based on the visibility of the search box
    setAnnouncement(!showSearch ? 'Search button open' : 'Search button collapsed');
  };
  // Focus the search box when it becomes visible
  useEffect(() => {
    if (showSearch) {
      const searchBox = document.getElementById('search-box');
      if (searchBox) {
        searchBox.focus();
      }
    }
  }, [showSearch]);

   // Close the search box on pressing "Esc"
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && showSearch) {
        setShowSearch(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [showSearch]);

  return (
    <header>
      <nav aria-label="Main navigation">
        <ul >
          <li>
            <Link to="/menu" className='menu'>Menu</Link>
          </li>
          <li>
            <Link to="/" className='menu'>
              Home
              <span>The accessible store</span>
            </Link>
          </li>
          <li>
            <button
              className="menu"
              onClick={handleSearchClick} 
              aria-expanded={showSearch ? "true" : "false"}
              aria-label="search button"
            >
              Search
            </button>
          </li>
        </ul>
      </nav>
      <div aria-live="assertive" aria-atomic="true" style={{ position: 'absolute', left: '-9999px' }}>
        {announcement}
      </div>
      {showSearch && (
        <div className='input-search'>
          <input
            type="text"
            placeholder="Search..."
            aria-label="Search for products"
            role="searchbox"
          />
        </div>
      )}
    </header>
  );
};

export default Header;
