import React from 'react';
import { Link } from 'react-router-dom';
// import { ChevronDown } from 'lucide-react';

const FilterAccordion = ({ filterCount }) => {
  return (    
    <div id='filter-dropdown' className="dropdown  mt-3">
      <button 
          id="filter-dropdown-button"
          className="filter dropdown-toggle" 
          aria-expanded="false"
          aria-haspopup="true"
          aria-controls="filterMenu"
          data-bs-toggle="dropdown"
      >
          Filters ({filterCount})
          <i className="las la-angle-down"></i>
      </button>
      <ul 
          className="dropdown-menu" 
          id="filterMenu" 
          aria-labelledby="dropdownMenuButton" 
          role="menu"
      >
          <li role="none">
            <Link className="dropdown-item" href="#" role="menuitem">Filter 1</Link>
          </li>
          <li role="none">
          <Link className="dropdown-item" href="#" role="menuitem">Filter 2</Link>
          </li>
          <li role="none">
          <Link className="dropdown-item" href="#" role="menuitem">Filter 3</Link>
          </li>
      </ul>
    </div>
  );
};

export default FilterAccordion;