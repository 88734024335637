import React, {useEffect, useState} from "react";
import './Home.scss';
import { Link } from 'react-router-dom';
// import arrowRight from '../assets/Icons/arrowright.svg';
import Electronics from '../assets/Icons/electronic.svg';
import Beauty from '../assets/Icons/beauty.svg';
import Fashion from '../assets/Icons/fashion.svg';

const categories = [
    {
        name: "Electronics",
        path: "/electronics",
        imgSrc: Electronics,
        altText: "Electronics icon",
        ariaLabel: "Browse electronics category",
    },
    {
        name: "Beauty",
        path: "/beauty",
        imgSrc: Beauty,
        altText: "Beauty icon",
        ariaLabel: "Browse beauty category",
    },
    {
        name: "Fashion",
        path: "/fashion",
        imgSrc: Fashion,
        altText: "Fashion icon",
        ariaLabel: "Browse fashion category",
    },
    {
        name: "Food",
        path: "/food",
        imgSrc: Beauty, // Replace with an actual food icon
        altText: "Food icon",
        ariaLabel: "Browse food category",
    },
    {
        name: "Books & Audiobooks",
        path: "/books",
        imgSrc: Electronics, // Replace with an actual books icon
        altText: "Books and audiobooks icon",
        ariaLabel: "Browse Books & Audiobooks category",
    },
];

const Home = () => {

    // const [categories, setCategories] = useState([]);

    // useEffect(() => {
    //     const fetchCategories = async () => {
    //         try {
    //             const response = await fetch('/api/categories'); // Adjust the URL to match your Node endpoint
    //             const data = await response.json();
    //             setCategories(data); // Assuming data is in the desired format
    //         } catch (error) {
    //             console.error('Error fetching categories:', error);
    //         }
    //     };

    //     fetchCategories();
    // }, []);

    return (
        <main>
            <section className="home-section">
                <h1>The accessible store</h1>
                <p>Online shopping for the visually impaired.</p>

                <div className="home-section-content">
                    <Link to="/howitworks" className="heading">
                        <span>How it works</span>
                        {/* <img src={arrowRight} alt="Learn more about how the store works" /> */}
                        <i className="las la-angle-right"></i>
                    </Link>
                    <p>Total 25 stores connected.</p>
                    <span>Choose a category</span>
                    <div className="category mt-4">
                        {categories.map((category, index) => (
                            <Link
                                key={index}
                                to={category.path}
                                className="btn-category"
                                aria-label={category.ariaLabel}
                            >
                                <img src={category.imgSrc} alt={category.altText} />
                                {category.name}
                            </Link>
                        ))}
                    </div>
                </div>
                {/* <button className="btn btn-back" aria-label="Go back">Back</button>      */}
            </section>
        </main>
    );
};

export default Home;
