import React from "react";
import { useNavigate } from 'react-router-dom';

const Beauty = () => {
    const navigate = useNavigate(); // Initialize the hook
    const handleBackClick = () => {
        navigate('/'); // Navigate to home page
    };
    return (
        <div className="beauty" role="main" style={{background:'#f2f2f2', padding:'32px 20px'}}>
            <h1>Beauty</h1>
            <p>2 stores in the Beauty category</p>
            <div className="category mt-4">
                <button className="btn btn-category" aria-label="Browse Skincare category">
                    Skincare
                </button>
                <button className="btn btn-category" aria-label="Browse Makeup category">
                    Makeup
                </button>
            </div>

            <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">Back</button>
        </div>
    );
}

export default Beauty;