import React from "react";
import { useNavigate } from 'react-router-dom';

const Books_audiobook = () => {
    const navigate = useNavigate(); // Initialize the hook

    const handleBackClick = () => {
        navigate('/'); // Navigate to home page
    }

    return(
        <div className="books_audiobook" role="main" style={{background:'#f2f2f2', padding:'32px 20px'}}>
            <h1>Books & Audiobooks</h1>
            <p>2 stores in the Books & Audiobooks category</p>
            <div className="category mt-4">
                <button className="btn btn-category" aria-label="Browse Books category">
                    Books
                </button>
                <button className="btn btn-category" aria-label="Browse Audiobooks category">
                    Audiobooks
                </button>
            </div>
            <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">Back</button>
        </div>
    );
}

export default Books_audiobook;