import React from "react";
import { useNavigate } from 'react-router-dom';

const Electronics = () => {
    const navigate = useNavigate(); // Initialize the hook

    const handleBackClick = () => {
        navigate('/'); // Navigate to home page
    }

    return (
        <div className="electronics" role="main" style={{background:'#f2f2f2', padding: '32px 20px'}}>
            <h1>Electronics</h1>
            <p>2 stores in the Electronics category</p>
            <div className="category mt-4">
                <button className="btn btn-category" aria-label="Browse Smartphones category">
                    Smartphones
                </button>
                <button className="btn btn-category" aria-label="Browse Laptops category">
                    Laptops
                </button>
            </div>

            <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">Back</button>
        </div>
    );
}

export default Electronics;