import React from "react";
import { Link } from 'react-router-dom';
import Electronics from '../assets/Icons/electronic.svg';
import Beauty from '../assets/Icons/beauty.svg';
import Fashion from '../assets/Icons/fashion.svg';
import './Menu.scss';
import { useNavigate } from 'react-router-dom';


const Menu = () => {
    const navigate = useNavigate(); // Initialize the hook
    const handleBackClick = () => {
        navigate('/'); // Navigate to home page
    };
    
    return (
        <div className="menu" role="main">
            <h1>Choose a category</h1>
            <div className="category mt-4">
                <Link to="/fashion" className="btn-category" aria-label="Browse electronics category">
                    <img src={Electronics} alt="Electronics icon" />
                    Electronics
                </Link>
                <Link to="/fashion" className="btn-category" aria-label="Browse beauty category">
                    <img src={Beauty} alt="Beauty icon" />
                    Beauty
                </Link>
                <Link to="/fashion" className="btn-category" aria-label="Browse fashion category">
                    <img src={Fashion} alt="Fashion icon" />
                    Fashion
                </Link>
                <Link className="btn-category" aria-label="Browse food category">
                    <img src={Beauty} alt="Beauty icon" />
                    Food
                </Link>
                <Link to="/fashion" className="btn-category" aria-label="Browse Books & Audiobooks category" >
                    <img src={Electronics} alt="Books and audiobooks icon" />
                    Books & Audiobooks
                </Link>
                <span id="browse-books-description" style={{ display: 'none' }}>
                    Explore a wide variety of books and audiobooks available in our collection.
                </span>

            </div>

            <button className="btn btn-back" onClick={handleBackClick} aria-label="Go back">Back</button>
        </div>
    );
};

export default Menu;