import React from "react";
import { useNavigate } from 'react-router-dom';

// Define product categories for different titles
const productCategoriesMap = {
    "Womenswear": [
        { name: "Women’s tops", count: 350, ariaLabel: "Browse Women’s tops (350) category" },
        { name: "T-shirts", count: 23, ariaLabel: "Browse T-shirts (23) category" },
        { name: "Skirts", count: 15, ariaLabel: "Browse Skirts (15) category" },
        { name: "Boots", count: 140, ariaLabel: "Browse Boots (140) category" },
        { name: "Accessories", count: 12, ariaLabel: "Browse Accessories (12) category" }
    ],
    "Menswear": [
        { name: "Men’s shirts", count: 120, ariaLabel: "Browse Men’s shirts (120) category" },
        { name: "Jeans", count: 50, ariaLabel: "Browse Jeans (50) category" },
        { name: "Men’s shoes", count: 80, ariaLabel: "Browse Men’s shoes (80) category" },
        // Add more categories for Menswear as needed
    ],
    "Kids": [
        { name: "Kids' clothing", count: 300, ariaLabel: "Browse Kids' clothing (300) category" },
        { name: "Kids' shoes", count: 150, ariaLabel: "Browse Kids' shoes (150) category" },
        // Add more categories for Kids as needed
    ]
};

const H_MList = ({ title }) => {
    const navigate = useNavigate();

    const handleOnProductClick = (category) => {
        navigate('/productlist', { state: { category } }); // Pass category details when navigating
    };

    // Get the product categories based on the title prop
    const productCategories = productCategoriesMap[title] || [];

    return (
        <div>
            <span>{title}</span> {/* Display dynamic title */}
            <div className="mt-4">
                <h2>Choose a category</h2>
                <div className="category">
                    {productCategories.map((product, index) => (
                        <button
                            key={index}
                            onClick={() => handleOnProductClick(product.name)}
                            className="btn btn-category"
                            aria-label={product.ariaLabel}
                        >
                            {product.name} ({product.count})
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default H_MList;
