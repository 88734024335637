import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = ({  id, name, price, imageUrl  }) => {
  return (
    <div id='productcard' className="product" >
      <Link to={`/product/${id}`} className="product-link">
        <img src={imageUrl} alt={name} id='filter-img' className="filter-img" />
        <div className="details">
          <h4 id='product-name'>{name}</h4>
          <p id='product-price'>Price: ${price.toFixed(2)}</p>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;