import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import './Fashion.scss';

const Fashion = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/'); // Navigate to home page
    };

    const stores = [
        {
            name: "H&M",
            path: "/H&M",
            ariaLabel: "Browse H&M category"
        },
        {
            name: "Zara",
            path: "/H&M",
            ariaLabel: "Browse Zara category"
        },
        {
            name: "Marks and Spencer",
            path: "/H&M",
            ariaLabel: "Browse Marks and Spencer category"
        }
    ];

    return (
        <div className="fashion" role="main">
            <h1>Fashion</h1>
            <h5>{stores.length} stores in the fashion category</h5>
            <div className="category">
                {stores.map((store, index) => (
                    <Link
                        key={index}
                        to={store.path}
                        className="btn-category"
                        aria-label={store.ariaLabel}
                    >
                        {store.name}
                    </Link>
                ))}
            </div>
            <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">
                Back
            </button>
        </div>
    );
};

export default Fashion;
