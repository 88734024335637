import React, { useState } from "react";
import Tshirt from "../assets/Images/t-shirt.png";

const CartCard = ({ item, removeCard }) => {
  const [editMode, setEditMode] = useState(false);
  const [quantity, setQuantity] = useState(item.quantity);
  const [size, setSize] = useState(item.size);
  const [color, setColor] = useState(item.color);

  const total = (quantity * item.price).toFixed(2);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSave = () => {
    setEditMode(false);
    // Logic to handle saving can be added here
  };

  return (
    <div className="product">
      {/* <img src={item.imageUrl} alt={item.name} className="filter-img px-4" /> Dynamically set image URL */}
      <img src={Tshirt} alt={item.name} className="filter-img px-4" /> {/* Static image URL */}
      <div className="details">
        <h2 className="">{item.name}</h2>
        <div className="table">
          <div className="table-row">
            <div className="fw-bold td">Price:</div>
            <div className="fw-medium td">${item.price}</div>
          </div>
          <div className="table-row">
            <div className="fw-bold td">Quantity:</div>
            <div className="fw-medium td">
              {editMode ? (
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Math.max(1, e.target.value))}
                  min="1"
                  className="form-control"
                  aria-label="Quantity"
                />
              ) : (
                quantity
              )}
            </div>
          </div>
          <div className="table-row">
            <div className="fw-bold td">Size:</div>
            <div className="fw-medium td">{editMode ? (
              <select
                value={size}
                onChange={(e) => setSize(e.target.value)}
                className="form-control"
                aria-label="Select size"
              >
                {/* Assuming size options are static */}
                {["Small", "Medium", "Large"].map(sizeOption => (
                  <option key={sizeOption} value={sizeOption}>{sizeOption}</option>
                ))}
              </select>
            ) : (
              size
            )}</div>
          </div>
          <div className="table-row">
            <div className="fw-bold td">Color:</div>
            <div className="fw-medium td">{editMode ? (
              <select
                value={color}
                onChange={(e) => setColor(e.target.value)}
                className="form-control"
                aria-label="Select color"
              >
                {/* Assuming color options are static */}
                {["Blue", "Red", "Green"].map(colorOption => (
                  <option key={colorOption} value={colorOption}>{colorOption}</option>
                ))}
              </select>
            ) : (
              color
            )}</div>
          </div>
          <div className="table-row">
            <div className="fw-bold td">Total:</div>
            <div className="fw-bold td">${total}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4">
          {editMode ? (
            <button className="btn btn-success" onClick={handleSave}>
              <i className="las la-save me-2"></i>Save
            </button>
          ) : (
            <button className="btn btn-info" onClick={toggleEditMode}>
              <i className="las la-edit me-2"></i> Edit
            </button>
          )}
          <button className="btn btn-danger" onClick={() => removeCard(item.id)}>
            <i className="las la-window-close me-2"></i> Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
