import React, { useContext } from "react";
import Cart from './Cart';
import './CartList.scss';
import CartCard from "./CartCard";
import { useNavigate } from 'react-router-dom';
import { CartContext } from "./context/CartContext";
import Tshirt from "../assets/Images/t-shirt.png";

const CartList = () => {
    const navigate = useNavigate();
    const { cartItems, removeFromCart } = useContext(CartContext);

    const handleBackClick = () => {
        navigate('/productlist');
    };

    return (
        <div className="cart-list" role="main">
            <Cart />
            {cartItems.length > 0 ? (
                cartItems.map(item => (
                    <CartCard
                        key={`${item.id}-${item.size}-${item.color}`} // Unique key to prevent duplicates
                        item={item}
                        removeCard={removeFromCart}
                    />
                ))
            ) : (
                <p>Your cart is empty</p>
            )}

            <button className="btn btn-primary">Checkout</button>
            <button className="btn btn-back" onClick={handleBackClick}>Back</button>
        </div>
    );
};

export default CartList;
