import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Menu from './components/Menu';
import Electronics from './components/Electronics';
import Beauty from './components/Beauty';
import Fashion from './components/Fashion';
import Food from './components/Food';
import Books from './components/Books_audiobook';
import H_M from './components/H&M';
import ProductList from './components/ProductList';
import ProductDetails from './components/ProductDetails';
import CartList from './components/CartList';
import HowItWorks from './components/HowItWorks';



function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/electronics" element={<Electronics />} />
          <Route path="/beauty" element={<Beauty />} />
          <Route path="/fashion" element={<Fashion />} />
          <Route path='/food' element={<Food />} />
          <Route path='/books' element={<Books />} />
          <Route path='/H&M' element={<H_M />} />
          <Route path='/productlist' element={<ProductList />} />
          <Route path='/product/:id' element={<ProductDetails />} />
          <Route path='/cartlist' element={<CartList />} />
          <Route path='/howitworks' element={<HowItWorks />} />
          {/* Add other routes here as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
