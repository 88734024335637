import React from "react";
import { useNavigate } from 'react-router-dom';

const Food = () => {
    const navigate = useNavigate(); // Initialize the hook
    const handleBackClick = () => {
        navigate('/'); // Navigate to home page
    }


    return (
        <div className="food" role="main" style={{background:'#f2f2f2', padding:'32px 20px'}}>
            <h1>Food</h1>
            <p>2 stores in the Food category</p>
            <div className="category mt-4">
                <button className="btn btn-category" aria-label="Browse Groceries category">
                    Groceries
                </button>
                <button className="btn btn-category" aria-label="Browse Restaurants category">
                    Restaurants
                </button>
            </div>

            <button onClick={handleBackClick} className="btn btn-back" aria-label="Go back">Back</button>
        </div>
    );
}

export default Food;